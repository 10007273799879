import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import Section1 from "../../components/Section1/section1.js";
import Section2 from "../../components/Section2/Section2.js";
import Section3 from "../../components/Section3/Section3.js";
import Section4 from "../../components/Section4/Section4.js";
import Section5 from "../../components/Section5/Section5.js";
import Section6 from "../../components/Section6/Section6.js";
import Section7 from "../../components/Section7/Section7.js";
import Section8 from "../../components/Section8/Section8.js";
import Section9 from "../../components/Section9/Section9.js";
import Section10 from "../../components/Section10/Section10.js";
import Section11 from "../../components/Section11/Section11.js";
import Testimonial from "../../components/Testimonial/Testimonial.js";
import Section12 from "../../components/Section12/Section12.js";
import NewsLetter from "../../components/NewsLetter/NewsLetter.js";
import resourceImg1 from '../../assets/scenarios/user-driven/13.webp'
import resourceImg2 from '../../assets/scenarios/user-driven/12.webp'
import resourceImg3 from '../../assets/scenarios/user-driven/4.webp'
import resourceImg4 from '../../assets/scenarios/user-driven/Pricing.webp'
import resourceImg5 from '../../assets/scenarios/user-driven/1.webp'
import resourceImg6 from '../../assets/scenarios/user-driven/11.webp'
import resourceImg7 from '../../assets/scenarios/user-driven/15.webp'
import resourceImg8 from '../../assets/scenarios/user-driven/6.webp'
import resourceImg9 from '../../assets/scenarios/user-driven/16.webp'
import resourceImg10 from '../../assets/scenarios/user-driven/7.webp'
import resourceImg11 from '../../assets/scenarios/user-driven/9.webp'
import resourceImg12 from '../../assets/scenarios/user-driven/2.webp'
import resourceImg13 from '../../assets/scenarios/user-driven/8.webp'

import industryImg1 from '../../assets/scenarios/industry/life-insurance.webp'
import industryImg2 from '../../assets/scenarios/industry/health-insurance.webp'
import industryImg3 from '../../assets/scenarios/industry/24.webp'
import industryImg4 from '../../assets/scenarios/industry/distributors-and-brokers.webp'
import industryImg5 from '../../assets/scenarios/industry/19.webp'
import industryImg6 from '../../assets/scenarios/industry/sme-insurance.webp'
import industryImg7 from '../../assets/scenarios/industry/17.webp'
import industryImg8 from '../../assets/scenarios/industry/18.webp'
import industryImg9 from '../../assets/scenarios/industry/21.webp'
import industryImg10 from '../../assets/scenarios/industry/micro-insurance.webp'

import sbilogo from '../../assets/Logos_Pictures/Clients/sbilogo.png'
import sbilogonew from '../../assets/Logos_Pictures/Clients/image 16.png'
import aegonlife from '../../assets/Logos_Pictures/Clients/image 20.png'
import client1 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - AEGON Life.webp'
import client2 from '../../assets/Logos_Pictures/Clients/image 18.png'
import client3 from '../../assets/Logos_Pictures/Clients/image 21.png'
import client4 from '../../assets/Logos_Pictures/Clients/image 17.png'
import client5 from '../../assets/Logos_Pictures/Clients/image 19.png'
import client6 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - IFFCO TOKIO.webp'
import client7 from '../../assets/Logos_Pictures/Clients/Customer Logo - IDFC FIRST BANK.webp'
import client8 from '../../assets/Logos_Pictures/Clients/Artivatic Client Logo - RenewBuy.webp'
import client9 from '../../assets/Logos_Pictures/Clients/image 23.png'
import client10 from '../../assets/Logos_Pictures/Clients/image 24.png'
import clientNewgen from '../../assets/Logos_Pictures/Clients/newgen.png'

import aspireLogo from '../../assets/products/Aspire/ASPIRE.webp'

import prodxDesignLogo from '../../assets/products/PRODX- Design/PRODX DESIGN.webp'
import prodxDistLogo from '../../assets/products/PRODX- Distribution/PRODX DISTRIBUTION.webp'

import axios from "axios";
import "./Home.css";

// const api = axios.create({
//   baseURL: 'https://artivatic.herokuapp.com/api/'
// })

export default function Home() {

  // const [apiData, setApiData] = useState([])

  // useEffect(() => {
  //   api.get('team/')
  //     .then(res => { setApiData(res.data) })
  //     .catch(err => console.log(err))

  // }, [])

  // console.log(apiData);

  var userCarousel = [
    {
      type: "hover",
      title: "Insurance",
      text: " First of its kind, self-managed insurance platform for SME/Business to manage all insurance products.",
      cardImg: resourceImg1
    },
    {
      type: "hover",
      title: "API Insurance Infra",
      text: "Modernizing API Infrastructure for all your insurance needs. Location Intelligence- BETA VERSION of INFRD Insurance APIs Cloud Platform gather actionable location intelligence.",
      cardImg: resourceImg2
    },
    {
      type: "hover",
      title: "Location Intelligence",
      text: "BETA VERSION of INFRD Insurance APIs Cloud Platform gather actionable location intelligence.",
      cardImg: resourceImg3
    },
    {
      type: "hover",
      title: "Pricing & Personalization",
      text: "Artivatic’s technology-based platform streamlines the pricing and personalization process in real-time enhancing the customer experience.",
      cardImg: resourceImg4
    },
    {
      type: "hover",
      title: "OCR/ICR",
      text: "In-house built algorithms and models to provide document reading, matching and analysis",
      cardImg: resourceImg5
    },
    {
      type: "hover",
      title: "Medical/Provider Network",
      text: "AI-Driven 360-degree platform that connects patients, providers, and insurance.",
      cardImg: resourceImg6
    },
    {
      type: "hover",
      title: "Tariff Management",
      text: "Build partner network, manager products, manage rules, maker & checker, and more.",
      cardImg: resourceImg7
    },
    {
      type: "hover",
      title: "Risk Inspection",
      text: "ProdX allows the use of historical data, underwriting risks, claims, location/geospatial data, and more to provide insights for creating risk-based products under 60 mins.",
      cardImg: resourceImg8
    },
    {
      type: "hover",
      title: "Payments & Settlements",
      text: "Our AI-built platform helps in the settlement of payment, buy, renew, file claims in real-time.",
      cardImg: resourceImg9
    },
    {
      type: "hover",
      title: "Early claims",
      text: "AI Health Claims Platform build 360 health ecosystem using tech & data to transform real-time claims process settlement.",
      cardImg: resourceImg10
    },
    {
      type: "hover",
      title: "Customer Persistency",
      text: "Design new products based on customer need get approval, integrate and sell.",
      cardImg: resourceImg11
    },
    {
      type: "hover",
      title: "Lead Management",
      text: "MiO, our integrated AI platform empowers insurance players with specialized lead management and scoring process.",
      cardImg: resourceImg12
    },
    {
      type: "hover",
      title: "CRM",
      text: "MiO Platform providing unified Platform for entire Agent PoS Process.",
      cardImg: resourceImg13
    },
  ]

  var industryCarousel = [
    {
      type: "hover",
      title: "Life Insurance",
      text: "Embedded with 400+ APIs and 8+ Core platforms, Artivatic transforms Life Insurance for New Age Insurance.",
      cardImg: industryImg1
    },
    {
      type: "hover",
      title: "Health Insurance",
      text: "Simple & Quick Integration with the existing environment with the use of APIs /SDKs & Full-stack on-premise setup re-imagines digital insurance for Modern Experience Health Insurance.",
      cardImg: industryImg2
    },
    {
      type: "hover",
      title: "Property Insurance",
      text: "An end-to-end risk inspection system that connects with multiple systems via way of APIs.",
      cardImg: industryImg3
    },
    {
      type: "hover",
      title: "Distributors & Brokers",
      text: "Our self-service API-driven SaaS platform allow partners, brokers, and distributors to integrate insurance products to further sell to their customers.",
      cardImg: industryImg4
    },
    {
      type: "hover",
      title: "Group Health",
      text: "ASPIRE BUSINESS enables real-time Group Health & SME/Business Underwriting system connects for personalized policy issuance.",
      cardImg: industryImg5
    },
    {
      type: "hover",
      title: "SME Insurance",
      text: "Aspire business enabling SME/enterprises to self-buy, manage personalized insurance for employees & indemnity digitally.",
      cardImg: industryImg6
    },
    {
      type: "hover",
      title: "Commercial Insurance",
      text: "Full-stack API Based self-managed business insurance platform that allows creating accounts, buying policies digitally, connecting to 3rd parties, building risk, and more.",
      cardImg: industryImg7
    },
    {
      type: "hover",
      title: "Enterprises & Fintechs",
      text: "Our SaaS-based and Data-driven platform simplifies Commercial Insurance for Businesses, SMEs & enterprises.",
      cardImg: industryImg8
    },
    {
      type: "hover",
      title: "Hospital & TPAs",
      text: "With a flexible engagement approach, Artivatic seamlessly enables healthcare providers to enhance their technology infrastructure and improve their net revenue.",
      cardImg: industryImg9
    },
    {
      type: "hover",
      title: "Micro Insurance",
      text: "Artivatic’s existing API & Risk assessment platform will enable to manufacture of new products in quick time with individual risk assessment for any insurance category.",
      cardImg: industryImg10
    },
  ]

  const clientList = [
    {
      file: sbilogonew,
      flag: 'sbi'
    },
    {
      file: aegonlife,
      flag: 'rect'
    },
    {
      file: client2,
      flag: 'square'
    },
    {
      file: client3,
      flag: 'rect'
    },
    {
      file: client4,
      flag: 'rect'
    },
    {
      file: client5,
      flag: 'rect'
    },
    {
      file: client8,
      flag: 'rect'
    },
    {
      file: client9,
      flag: 'square'
    },
    {
      file: client10,
      flag: 'rect'
    },
    {
      file: clientNewgen,
      flag: 'rect'
    }

    // sbilogonew, aegonlife, client2, client3, client4, client5, client8, client9, client10
  ]



  return (
    <>
      <Helmet>
        <title>Artivatic | AI Insurance Platform</title>

        <meta name="description" content="Positively Enabling Affordable Insurance Access.Join us in revolutionizing the Insurance ecosystem." />

        <meta name="keywords" content="Artivatic,Insurance Cloud,Awards and Recognition,Industry Solution,
        Over 20+ native integrations out of the box,Location Intelligence,Risk & Fraud,OCR/ICR,Provider Network,Medical,Tariff Management,Inspection Systems,Lead Management,CRM,OEM,Sales Marketing Mio."/>

        <link rel="canonical" href="https://artivatic.ai/" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://artivatic.ai/`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Artivatic | AI Insurance Platform`} />
        <meta property="og:description" content={`Positively Enabling Affordable Insurance Access.Join us in revolutionizing the Insurance ecosystem.`} />
        <meta property="og:image" content={`https://artivatic.ai/static/media/img_1.51883401.png`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />

        {/* Twitter Meta Tags  */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="artivatic.ai" />
        <meta property="twitter:url" content={`https://artivatic.ai/`} />
        <meta name="twitter:title" content={`Artivatic | AI Insurance Platform`} />
        <meta name="twitter:description" content={`Positively Enabling Affordable Insurance Access.Join us in revolutionizing the Insurance ecosystem.`} />
        <meta name="twitter:image" content={`https://artivatic.ai/static/media/img_1.51883401.png`} />
        <meta property="og:image:width" content='1200' />
        <meta property="og:image:height" content='630' />


        {/* Organisation Schema  */}
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Corporation",
            "name": "Artivatic.ai",
            "alternateName": "Artivatic",
            "url": "https://artivatic.ai/",
            "logo": "https://artivatic.ai/favicon.jpeg",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+91 8431969670",
              "contactType": "customer service",
              "areaServed": ["IN", "US"],
              "availableLanguage": "en"
            },
            "sameAs": [
              "https://www.facebook.com/artivatic",
              "https://twitter.com/artivatic",
              "https://www.instagram.com/artivatic.ai/?hl=en",
              "https://www.youtube.com/channel/UCErCyc2yw9zKPuLm3WrANZQ",
              "https://www.linkedin.com/company/artivatic"
            ]
          }`}
        </script>
      </Helmet>
      <div id="home">
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section9
          heading='Artivatic has products designed for all user driven scenarios.'
          courosalValues={userCarousel}
          id={'user-carousel'}
          properties={{
            sectionClass: '',
            headingClass: '',
            navbutton: '',
            containerClass: ''
          }}
        />
        {/* <Section6 /> */}
        <Section7
          title={
            {
              line1: 'Our Products are',
              line2: 'adding new Cognitive capabilities',
              line3green: true,
              greenText: 'for Insurers.',
            }
          }
          subtitle={
            { text: 'Artivatic makes all your tools work better by letting you integrate industry-leading software and custom apps.', }
          }
          cards={[
            // { Title: "INFRD", Description: "A holistic Next-Gen rapid product designing with risk insights.", btnLink: '/', imgLink: infrdLogo },
            // { Title: "ALFRED", Description: "A customised B2B2C distribution and embedded insurance platform for businesses.", btnLink: '/', imgLink: alfredLogo },
            { Title: "ASPIRE Business", Description: "An end to end personalized & affordable solution for employee health & business insurance.", btnLink: '/products/aspire', imgLink: aspireLogo },
            { Title: "ASPIRE Health", Description: "An end to end personalized & affordable solution for employee health & business insurance.", btnLink: '/products/aspire', imgLink: aspireLogo },
            // { Title: "AUSIS", Description: "A customised AI built & 3rd party data driven smart, real-time & personalized Underwriting Platform for Insurance.", btnLink: '/products/1', imgLink: ausisLogo },
            // { Title: "MiO Sales", Description: "AI embedded sales, communication & marketing platform for a complete insurance & financial services providers.", btnLink: '/products/4', imgLink: mioLogo },
            { Title: "PRODX Design", Description: "A holistic Next-Gen rapid product designing with risk insights.", btnLink: '/products/prodx', imgLink: prodxDesignLogo },
            { Title: "PRODX Distribution", Description: "A   customised   B2B2C   distribution   and   embedded   insurance   platform   for businesses.", btnLink: '/products/prodx', imgLink: prodxDistLogo },
          ]}
        />
        <Section8 />
        <Section9
          heading='Industry Solutions'
          courosalValues={industryCarousel}
          id={'industry-carousel'}
          properties={{
            sectionClass: '',
            headingClass: '',
            navbutton: '',
            containerClass: ''
          }}
        />
        <Section10 />
        <Section11
          title={{
            type: "spanend", Text: "Our customers are", span: "superheroes ", Text2: '– we are their growth partners'
          }}
          description={{
            Text: 'Artivatic is transforming legacy insurance into digital, personalized and customer centric while taking affordability of our clients into consideration. We make use of Artificial Intelligence and Machine Learning in our products and aim to integrate the same to uncomplicate what is an extremely archaic and manual industry to increase the number of insurance policy holders in the long run.',
            Text2: 'The purpose is to create a brighter future with our customers globally. Our customers range from individuals, small businesses, mid-sized and large companies to multinational corporations. We are committed to become the sustainable choice for our customers, because we understand that our role as an insurer is not only to help our customers protect themselves today, but to help them and our world adapt to the risks of tomorrow.'
          }}

          link={{
            Text: 'Read More',
            LinkRoute: '/customers',
          }}
          clientList={clientList}
        />
        <Testimonial
          title={{
            type: 'doublespan',
            Text1: 'believes in ',
            Span1: 'Artivatic ',
            Span2: 'growing ',
            Text2: 'together with all our stakeholders.'
          }}
        />
        <Section12 from="Home_page" />
        <NewsLetter
          title="Lets build next-gen insurance & health products"
          btn1text="Schedule a Demo"
          btn1Route="/contact-us"
          btn2Route="/contact-us"
          btn2text="Request Pricing" subscribeBtn="true"
        />
      </div>
    </>
  );
}
