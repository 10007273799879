import React,{ useRef } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = ({ title, classes, contactImg }) => {
  const nameRef =  useRef();
  const emailRef = useRef();
  const lastNameRef = useRef();
  const phoneRef = useRef();
  const topicRef = useRef();
  const desRef = useRef();

  const submitHandler = ()=>{
    const newObj = {
      name : nameRef.current.value,
      last_name : lastNameRef.current.value,
      email : emailRef.current.value,
      phone_number : phoneRef.current.value,
      topic : topicRef.current.value,
      description : desRef.current.value
    }
    nameRef.current.value = '';
    lastNameRef.current.value = '';
    emailRef.current.value = '';
    phoneRef.current.value = '';
    topicRef.current.value = '';
    desRef.current.value = '';
    console.log(newObj);
  }
  return (
    <section className="demo">
      <div className="banner">
        <img src={contactImg.src} className={"contact-detail-img " + (classes && classes.imageClass)} alt='' />
        
        <div className="contactdetails">
          <div className="row">
            {title && title.type === 'spanend' && (
              <div className="col-md-12">
                <label className={"heading " + (classes && classes.titleClass)}>
                  {title.Text}
                  <span style={{ color: "#22bb66" }}>{title.Span}</span></label>
              </div>
            )
            }
            <div className="col-sm-6">
              <input type="text" name="Name" placeholder="Name" className="inputField" ref={nameRef} />
            </div>
            <div className="col-sm-6">
              <input type="text" name="LastName" placeholder="Last Name" className="inputField" ref={lastNameRef}/>
            </div>
            <div className="col-sm-6">
              <input type="email" name="email" placeholder="Email" className="inputField" ref={emailRef}/>
            </div>
            <div className="col-sm-6">
              <input type="number" name="PhoneNumber" placeholder="Phone Number" className="inputField" ref={phoneRef}/>
            </div>
            <div className="col-md-12">
              <input type="text" name="topic" placeholder="Topic" className="inputField" ref={topicRef}/>
            </div>
            <div className="col-md-12">
              <textarea rows="10" className="inputField" placeholder="Description" ref={desRef}></textarea>
            </div>
            <div className="col-md-12">
              <button className="greenbutton" onClick={submitHandler}>Submit</button>
            </div>
          </div>
        </div>

      </div>
    </section >
  )
}

export default ContactForm
